import { GetServerSideProps } from "next";
import { LoginPage } from "../components/LoginPage";
import { getAuth } from "../util/authentication";

export default LoginPage;

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  const { isLoggedIn } = getAuth(ctx);
  if (isLoggedIn) {
    return {
      props: {},
      redirect: {
        destination: "/",
        permanent: false,
      },
    };
  }

  return {
    props: {},
  };
};
