import { NextPage } from "next";
import React from "react";
import Link from "next/link";
import { Container, Grid, Segment, Image } from "semantic-ui-react";
import { LoginForm } from "../LoginForm";
import Layout from "../Layout";

const LoginPage: NextPage<{
  createMode: boolean;
}> = ({ createMode }) => {
  return (
    <Layout>
      <Container className="login-container">
        <Grid centered doubling verticalAlign="middle" stretched padded>
          <Grid.Column computer={14} mobile={16}>
            <Segment>
              <LoginForm createMode={createMode} />
            </Segment>
          </Grid.Column>
          <Grid.Column width={16} textAlign="center">
            <Link href="/" passHref>
              <a>
                <div style={{ marginBottom: 15 }}>&larr; Back to Home</div>
                <Image
                  src={"/images/logo/brand-logo-sm.png"}
                  width={50}
                  centered
                />
              </a>
            </Link>
          </Grid.Column>
        </Grid>
      </Container>
    </Layout>
  );
};

export { LoginPage };
